import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { mediumFontSize } from '../cellFontSizes'

export const locationColumnId = 'location'

export const useLocationColumnHeader = (): MRT_ColumnDef<FailuresListEntry> => {
  return {
    id: locationColumnId,
    header: 'Location',
    accessorKey: 'location',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGrouping: true,
    enableColumnFilterModes: false,
    enableResizing: true,
    enableGlobalFilter: true,
    minSize: 100,
    maxSize: 200,
    getGroupingValue: (row: FailuresListEntry) =>
      row.location.location + '__' + row.location.technicalLocation,
    filterFn: 'contains',
    accessorFn: (row: FailuresListEntry) => {
      const location = row.location
      return `${location.location} ${location.technicalLocation}`.toUpperCase()
    },
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const locationFromCasesListEntry = row.original.location
      const location = locationFromCasesListEntry.location
      const technicalLocation = locationFromCasesListEntry.technicalLocation

      return (
        <Tooltip
          title={
            <Box>
              <Typography variant='body2'>{location}</Typography>
              <Typography variant='body2'>{technicalLocation}</Typography>
            </Box>
          }
        >
          <Box className='cell-location'>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {location}
            </Typography>
            <Typography variant='body2' fontSize={mediumFontSize}>
              {' '}
              {technicalLocation}
            </Typography>
          </Box>
        </Tooltip>
      )
    }
  }
}
