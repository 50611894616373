import Alert from '@mui/material/Alert'
import React from 'react'

interface IComponentErrorMessageProps {
  errorMessage: string
}

export const ComponentErrorMessage: React.FC<IComponentErrorMessageProps> = ({
  errorMessage
}) => {
  return (
    <Alert severity={'error'} variant='standard'>
      {`${errorMessage}: Please try again later.`}
    </Alert>
  )
}
