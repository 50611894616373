import { Box } from '@mui/material'
import React from 'react'
import { FailuresListEntry } from '../models/failuresListEntry'
import Alert from '@mui/material/Alert'
import { CommentsTable } from '../../CommentsTable/CommentsTable'
import { useFetchCase } from '../../Contexts'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'

interface IExpandableDetailPanelProps {
  entry: FailuresListEntry
}

export const ExpandableDetailPanel: React.FC<IExpandableDetailPanelProps> = (
  props: IExpandableDetailPanelProps
) => {
  const { caseDetailsData, isLoading, isError } = useFetchCase(
    props.entry.pumpId
  )

  if (isLoading || caseDetailsData === undefined) {
    return (
      <LoadingSpinner
        key={props.entry.pumpId + 'detailPanelLoading'}
        margin={'6'}
      />
    )
  }

  if (isError) {
    return (
      <Alert
        severity={'error'}
        variant='standard'
        key={props.entry.pumpId + 'detailPanelLoadingError'}
      >
        Error while fetching case details
      </Alert>
    )
  }

  return (
    <Box key={props.entry.pumpId + 'detailPanel'}>
      <Box key={props.entry.pumpId + 'detailPanelComments'}>
        <CommentsTable
          comments={caseDetailsData.comments}
          isReadOnly={true}
          caseId={props.entry.pumpId}
        />
      </Box>
    </Box>
  )
}
