import { Box, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import ModeEditIcon from '@mui/icons-material/ModeEdit'

interface ICommentsTableRowActions {
  onEdit: () => void
}

export const CommentsTableRowActions: React.FC<ICommentsTableRowActions> = (
  props: ICommentsTableRowActions
) => {
  return (
    <Box
      sx={{
        marginTop: '4px'
      }}
    >
      <Tooltip title='Edit'>
        <IconButton
          className=''
          onClick={props.onEdit}
          style={{ marginLeft: '0px' }}
        >
          <ModeEditIcon
            sx={{ color: '#0080f0', width: '16px', height: '16px' }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
