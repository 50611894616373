import { MRT_TableInstance } from 'material-react-table'
import { Comment } from '../Contexts/types'
import { Box, Button, TextField, Typography } from '@mui/material'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../Contexts/AuthContext'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

interface ICommentDialogProps {
  table: MRT_TableInstance<Comment>
  caseId: string
  comment?: Comment
  addComment?: (caseId: string, author: string, text: string) => Promise<void>
  updateComment?: (
    caseId: string,
    author: string,
    text: string,
    commentId: string
  ) => Promise<void>
  removeComment?: (caseId: string, commentId: string) => Promise<void>
}

export const CommentsTableDialog: React.FC<ICommentDialogProps> = (
  props: ICommentDialogProps
) => {
  const { userEmail } = useAuth()
  const [commentText, setCommentText] = useState<string | undefined>(
    props.comment?.text
  )
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const isLoading = useMemo<boolean>(
    () => isSaving || isRemoving,
    [isSaving, isRemoving]
  )
  const anyChanges = useMemo<boolean>(
    () => (props.comment?.text ?? '') !== commentText,
    [commentText, props.comment?.text]
  )
  const isNewComment = props.comment?.id === undefined
  const dialogContextTitle = isNewComment ? 'Create' : 'Update'
  const key = isNewComment
    ? 'CreateComment'
    : `UpdateComment${props.comment?.id}`

  const removeAction = async (): Promise<void> => {
    if (isNewComment || props.removeComment === undefined) {
      return Promise.resolve()
    }

    try {
      setIsRemoving(true)
      await props.removeComment(props.caseId, props.comment!.id)
    } finally {
      setIsRemoving(false)
      closeDialog()
    }
  }

  const submitAction = async (): Promise<void> => {
    if (isLoading || userEmail === undefined || commentText === undefined) {
      return Promise.resolve()
    }

    try {
      setIsSaving(true)
      if (isNewComment) {
        await props.addComment!(props.caseId, userEmail!, commentText!)
      } else {
        await props.updateComment!(
          props.caseId,
          userEmail!,
          commentText!,
          props.comment!.id
        )
      }
    } finally {
      setIsSaving(false)
      closeDialog()
    }
  }

  const closeDialog = (): void => {
    if (isNewComment) {
      props.table.setCreatingRow(null)
      return
    }
    props.table.setEditingRow(null)
  }

  const getDeleteButton = (): ReactNode => {
    if (isNewComment) {
      return <Box />
    }

    return (
      <Button
        variant='outlined'
        disabled={isLoading}
        color='error'
        sx={{ width: '100px' }}
        onClick={removeAction}
      >
        {isRemoving ? (
          <LoadingSpinner color={'error'} margin={'4'} />
        ) : (
          'Delete'
        )}
      </Button>
    )
  }

  const getSubmitButtonContent = (): ReactNode => {
    if (isSaving) {
      return <LoadingSpinner margin={'4'} />
    }
    return <Typography>{dialogContextTitle}</Typography>
  }

  useEffect(() => {
    return setHasErrors(
      commentText === undefined || commentText === '' || commentText === null
    )
  }, [commentText])

  return (
    <Box sx={{ padding: '12px' }} key={key}>
      <Typography variant='h6' sx={{ marginBottom: '6px' }}>
        {dialogContextTitle} Comment
      </Typography>
      <TextField
        defaultValue={commentText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCommentText(event.target.value)
        }}
        maxRows={20}
        minRows={10}
        multiline
        fullWidth
        required
      />
      <Box
        display={'flex'}
        sx={{ marginTop: '6px' }}
        justifyContent='space-between'
      >
        {getDeleteButton()}
        <Box>
          <Button
            variant='outlined'
            sx={{ marginLeft: '6px', width: '100px' }}
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={hasErrors || isLoading || !anyChanges}
            sx={{ marginLeft: '6px', width: '100px' }}
            onClick={submitAction}
          >
            {getSubmitButtonContent()}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
