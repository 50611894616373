import { useCallback, useRef } from 'react'
import { useFlashMessages } from '../../../FlashMessages/FlashMessages'

export function useCopyPumpInfoToClipboard() {
  const pumpDetailsClipboardElRef = useRef<HTMLDivElement>(null)
  const { showFlashMessage } = useFlashMessages()

  const copyDetailsToClipboard = useCallback(async () => {
    const html = pumpDetailsClipboardElRef.current?.innerHTML
    const text = pumpDetailsClipboardElRef.current?.innerText

    if (!html || !text) {
      return
    }

    try {
      const htmlBlob = new Blob([html], { type: 'text/html' })
      const textBlob = new Blob([text], { type: 'text/plain' })

      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': textBlob
      })

      await navigator.clipboard.write([clipboardItem])

      showFlashMessage('Copied pump details to clipboard', 'success')
    } catch (err) {
      console.error(err)
      showFlashMessage('Failed to copy pump details', 'error')
    }
  }, [showFlashMessage])

  return {
    pumpDetailsClipboardElRef,
    copyDetailsToClipboard
  }
}
