import { Typography } from '@mui/material'
import React from 'react'

interface ISectionTitleProps {
  title: string
}

export const SectionTitle: React.FC<ISectionTitleProps> = (
  props: ISectionTitleProps
) => {
  return (
    <Typography
      style={{ marginLeft: '0px', marginTop: '4px' }}
      sx={{
        marginRight: '12px',
        fontWeight: 'bold',
        fontSize: '16px'
      }}
    >
      {props.title}
    </Typography>
  )
}
