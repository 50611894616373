import { useEffect, useState } from 'react'
import { IAcknowledgmentSwitchProps } from './IAcknowledgmentSwitchProps'

export interface IUseAcknowledgementSwitch {
  isAcknowledged: boolean
  isLoading: boolean
  errorMessage: string | undefined
  onAcknowledgeSwitchChanged: () => Promise<void>
}

export const useAcknowledgementSwitch = (
  props: IAcknowledgmentSwitchProps
): IUseAcknowledgementSwitch => {
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(
    props.acknowledged
  )
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsAcknowledged(props.acknowledged)
  }, [props.acknowledged])

  const onAcknowledgeSwitchChanged = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await props.onAcknowledged(!isAcknowledged)
      setIsAcknowledged(response)
      setErrorMessage(undefined)
    } catch (error) {
      setErrorMessage(
        `Could not acknowledge failure type ${props.failureType.toUpperCase()}`
      )
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isAcknowledged,
    isLoading,
    errorMessage,
    onAcknowledgeSwitchChanged
  }
}
