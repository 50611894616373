import React, { FC, useState } from 'react'
import './AcknowledgmentSwitch.sass'
import { Grid, Switch } from '@mui/material'
import { useAcknowledgementSwitch } from './useAcknowledgementSwitch'
import { SnackbarNotification } from '../Snackbar/SnackbarNotification'
import { ComponentErrorMessage } from '../ComponentErrorMessage/ComponentErrorMessage'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { IAcknowledgmentSwitchProps } from './IAcknowledgmentSwitchProps'

export const AcknowledgmentSwitchListView: FC<IAcknowledgmentSwitchProps> = (
  props
) => {
  const {
    isAcknowledged,
    isLoading,
    errorMessage,
    onAcknowledgeSwitchChanged
  } = useAcknowledgementSwitch(props)

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  const handleAcknowledgeSwitchChange = async () => {
    await onAcknowledgeSwitchChanged()
    setOpenSnackbar(true)
  }

  const snackbarOnClose = () => {
    setOpenSnackbar(false)
  }

  if (errorMessage) {
    return <ComponentErrorMessage errorMessage={errorMessage} />
  }

  if (isLoading) {
    return <LoadingSpinner margin={'6'} />
  }

  return (
    <Grid container>
      <Switch
        size='small'
        checked={isAcknowledged}
        onChange={handleAcknowledgeSwitchChange}
        data-testid={`${props.caseId}-${props.failureType}-acknowledgement-toggle`}
      />
      <SnackbarNotification
        key={'MutingSnackbar'}
        message={isAcknowledged ? 'Acknowledged successfully' : errorMessage}
        onClose={snackbarOnClose}
        severity={errorMessage ? 'error' : 'info'}
        trigger={openSnackbar}
      />
    </Grid>
  )
}
