import { Alert, AlertColor, keyframes, Stack } from '@mui/material'
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

export interface FlashMessage {
  id: number
  message: string
  type: AlertColor
  timeoutId: ReturnType<typeof setTimeout>
  dataTestId?: string
}

export interface FlashMessagesContextProps {
  showFlashMessage: (
    message: string,
    type: AlertColor,
    dataTestId?: string
  ) => FlashMessageHandle
  showErrorMessage: (error: unknown, dataTestId?: string) => FlashMessageHandle
  handleClose: (id: number, timeoutId?: ReturnType<typeof setTimeout>) => void
  flashMessages: FlashMessage[]
}

export interface FlashMessageHandle extends FlashMessage {
  close: () => void
}

const FlashMessagesContext = createContext<
  FlashMessagesContextProps | undefined
>(undefined)

export const useFlashMessages = () => {
  const context = useContext(FlashMessagesContext)
  if (!context) {
    throw new Error(
      'useFlashMessages must be used within a FlashMessagesProvider'
    )
  }
  return context
}

export const FlashMessagesProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])

  const handleClose = useCallback(
    (id: number, timeoutId?: ReturnType<typeof setTimeout>) => {
      if (timeoutId) clearTimeout(timeoutId)
      setFlashMessages((prev) => prev.filter((msg) => msg.id !== id))
    },
    []
  )

  const showFlashMessage = useCallback(
    (
      message: string,
      type: AlertColor,
      dataTestId?: string
    ): FlashMessageHandle => {
      const id = Math.floor(Math.random() * 1_000_000)
      const timeoutId: any = setTimeout(() => handleClose(id, timeoutId), 5000)

      const flashMessage: FlashMessage = {
        id,
        message,
        type,
        timeoutId,
        dataTestId
      }

      setFlashMessages((prev) => [...prev, flashMessage])

      return {
        ...flashMessage,
        close: () => handleClose(id, timeoutId)
      }
    },
    [handleClose]
  )

  const showErrorMessage = useCallback(
    (error: unknown, dataTestId?: string) => {
      return showFlashMessage(
        (error as Error)?.message || 'Unknown error occurred',
        'error',
        dataTestId
      )
    },
    [showFlashMessage]
  )

  const value = useMemo(
    () => ({
      flashMessages,
      showFlashMessage,
      showErrorMessage,
      handleClose
    }),
    [flashMessages, showFlashMessage, showErrorMessage, handleClose]
  )

  return (
    <FlashMessagesContext.Provider value={value}>
      {children}
    </FlashMessagesContext.Provider>
  )
}

const KEYFRAMES = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`

export const FlashMessagesRenderer: FC<{ maxVisibleMessages?: number }> = ({
  maxVisibleMessages = 5
}) => {
  const { flashMessages, handleClose } = useFlashMessages()

  return (
    <Stack
      spacing={2}
      sx={(theme) => ({
        width: '100%',
        position: 'fixed',
        bottom: theme.spacing(4),
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1400,
        maxWidth: 500
      })}
    >
      {flashMessages.slice(-maxVisibleMessages).map((msg) => (
        <Alert
          key={msg.id}
          severity={msg.type}
          onClose={() => handleClose(msg.id, msg.timeoutId)}
          sx={(theme) => ({
            boxShadow: theme.shadows[3],
            animation: `${KEYFRAMES} 0.2s ${theme.transitions.easing.easeOut}`
          })}
        >
          {msg.message}
        </Alert>
      ))}
    </Stack>
  )
}
