import { useCaseContext } from '../../../Contexts'
import React, { useState } from 'react'
import { CaseDetailsContentDescriptionProps } from './CaseDetailsContentDescription'
import { CaseStatusEnum } from '../../../Contexts/types'

interface UseCaseDetailsContentDescriptionProperties {
  editDescription: string | undefined
  editMode: boolean
  isLoading: boolean
  handleCancel: () => void
  handleSaveDescription: () => Promise<void>
  handleFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  handleEditClick: () => void
  isSaveDescriptionDisabled: () => boolean
}

export const useCaseDetailsContentDescription = (
  props: CaseDetailsContentDescriptionProps
): UseCaseDetailsContentDescriptionProperties => {
  const { activeCase, setSnackbarMessage } = props

  const { updateCase } = useCaseContext()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [editDescription, setEditDescription] = useState<string | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCancel = () => {
    setEditMode(false)
    setIsLoading(false)
    setEditDescription(undefined)
  }

  const handleSaveDescription = async () => {
    const MAX_DESCRIPTION_LENGTH = 20000
    try {
      setIsLoading(true)

      const isDescriptionValid =
        editDescription &&
        editDescription.trim() !== '' &&
        editDescription.length <= MAX_DESCRIPTION_LENGTH

      if (!isDescriptionValid) {
        setSnackbarMessage('Description is not valid')
        return
      }

      await updateCase({
        id: activeCase.id,
        title: activeCase.title,
        assignee: activeCase.assignee,
        status:
          activeCase?.status === CaseStatusEnum.CLOSED
            ? CaseStatusEnum.CLOSED
            : CaseStatusEnum.OPEN,
        description: editDescription
      })

      setEditDescription(undefined)
      setIsLoading(false)
      setEditMode(false)
    } catch (error) {
      setEditMode(true)
      setIsLoading(false)
      if (error instanceof Error) {
        setSnackbarMessage(error.message)
      } else {
        setSnackbarMessage('Failed to change Description')
      }
    }
  }

  function handleFieldChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    setEditDescription(event.target.value)
  }

  const handleEditClick = () => {
    setEditMode(true)
    setEditDescription(activeCase?.description)
  }

  const isSaveDescriptionDisabled = () => {
    return (
      editDescription?.trim() === '' ||
      activeCase.description === editDescription
    )
  }

  return {
    editDescription,
    editMode,
    isLoading,
    handleCancel,
    handleSaveDescription,
    handleFieldChange,
    handleEditClick,
    isSaveDescriptionDisabled
  }
}
