import React, { FC } from 'react'
import './AcknowledgmentSwitch.sass'
import { Grid } from '@mui/material'
import { StatusSwitch } from '../StatusSwitch/statusSwitch'
import { useAcknowledgementSwitch } from './useAcknowledgementSwitch'
import { ComponentErrorMessage } from '../ComponentErrorMessage/ComponentErrorMessage'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { IAcknowledgmentSwitchProps } from './IAcknowledgmentSwitchProps'

export const AcknowledgmentSwitchDetailView: FC<IAcknowledgmentSwitchProps> = (
  props
) => {
  const {
    isAcknowledged,
    isLoading,
    errorMessage,
    onAcknowledgeSwitchChanged
  } = useAcknowledgementSwitch(props)

  if (errorMessage) {
    return <ComponentErrorMessage errorMessage={errorMessage} />
  }

  if (isLoading) {
    return <LoadingSpinner margin={'6'} />
  }

  return (
    <Grid container data-testid={`${props.failureType}-acknowledgement-toggle`}>
      <StatusSwitch
        label={isAcknowledged ? 'Acknowledged' : 'Not Acknowledged'}
        onChange={onAcknowledgeSwitchChanged}
        isChecked={isAcknowledged}
        width={'100%'}
      />
    </Grid>
  )
}
