import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { getFailureStateBackgroundColor } from '../../../utils/getFailureStateColor'
import { sortingFailureState } from '../../sortFunctions/sortingFailureState'
import { Box } from '@mui/material'

export const currentStateColumnId = 'currentState'

export const useCurrentStateColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const currentStates = data.map((entry) =>
      entry.currentState.replaceAll('FAILURESTATE_', '')
    )
    const distinctCurrentStates = currentStates.filter(
      (item, index) => currentStates.indexOf(item) === index
    )

    return distinctCurrentStates.sort((a, b) => (a > b ? 1 : -1))
  }, [data])

  return {
    id: currentStateColumnId,
    header: 'Current State',
    accessorKey: 'currentState',
    enableColumnDragging: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    enableResizing: false,
    minSize: 80,
    maxSize: 80,
    aggregationFn: 'unique',
    sortingFn: sortingFailureState,
    Cell: (data) => {
      const value = data.cell.getValue<string>().replaceAll('FAILURESTATE_', '')
      return (
        <Box
          className='cell-current-state failure-state-box'
          style={{
            backgroundColor: `${getFailureStateBackgroundColor(value)}`
          }}
        >
          {value}
        </Box>
      )
    }
  }
}
