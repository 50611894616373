import React, { FC } from 'react'
import { Box } from '@mui/material'
import {
  Failure,
  UpdatedFailureAcknowledgementData
} from '../../../../Contexts/types'
import { getFailureStateBackgroundColor } from '../../../../utils/getFailureStateColor'
import './alarmCard.sass'
import { AlarmCardActions } from './Actions/AlarmCardActions'
import { AlarmCardDetails } from './Details/alarmCardDetails'
import { AlarmCardHeader } from './Header/alarmCardHeader'

interface AlarmCardProps {
  acknowledged: boolean
  caseId: string
  failure: Failure
  updateFailureAcknowledgement: (
    acknowledgement: UpdatedFailureAcknowledgementData
  ) => void
}

export const AlarmCard: FC<AlarmCardProps> = ({
  acknowledged,
  caseId,
  failure,
  updateFailureAcknowledgement
}) => {
  const backgroundColor = getFailureStateBackgroundColor(failure.failureState)

  return (
    <Box
      data-testid={`${failure.failureType}-alarm-card`}
      className='alarm-card'
      sx={{
        backgroundColor: backgroundColor
      }}
    >
      <AlarmCardHeader failure={failure} />
      <AlarmCardDetails failure={failure} />
      <AlarmCardActions
        caseId={caseId}
        failure={failure}
        updateFailureAcknowledgement={updateFailureAcknowledgement}
      />
    </Box>
  )
}
