import { TagsList } from '../../../TagsList'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { TextField } from '@mui/material'
import { FormButton } from '../../../FormButton'
import React from 'react'
import { Case } from '../../../Contexts/types'
import { useCaseDetailsContentTags } from './useCaseDetailsContentTags'
import { DebugBorder } from '../../../utils/debugBorder'

export interface CaseDetailsContentTagsProps {
  activeCase: Case
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}

export const CaseDetailsContentTags: React.FC<CaseDetailsContentTagsProps> = (
  props
) => {
  const { activeCase } = props
  const {
    editMode,
    editTag,
    isLoading,
    startEditMode,
    handleCancel,
    handleFieldChange,
    handleSaveNewTag
  } = useCaseDetailsContentTags(props)

  return (
    <DebugBorder>
      <TagsList title={'Tags'} tags={activeCase.tags} removable={true}>
        {!editMode && (
          <IconButton onClick={startEditMode} size={'small'}>
            <AddCircleOutlineIcon
              sx={{
                padding: '0',
                color: '#0080f0'
              }}
            />
          </IconButton>
        )}
        {editMode && (
          <form
            onSubmit={handleSaveNewTag}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <TextField
              autoFocus
              value={editTag}
              onChange={(event) => handleFieldChange(event)}
              rows={1}
              onKeyDown={(e) => e.key === 'Escape' && handleCancel}
              margin='normal'
              className='tag-input__input'
              variant='outlined'
              sx={{
                margin: 0,
                padding: 0
              }}
            />
            <FormButton
              type='submit'
              variant='accept'
              disabled={editTag === '' || isLoading}
            />
            <FormButton
              variant='decline'
              disabled={isLoading}
              onClick={handleCancel}
            />
          </form>
        )}
      </TagsList>
    </DebugBorder>
  )
}
