import React from 'react'

const labelMap: Record<string, string> = {
  organizationName: 'Organization',
  customerId: 'Customer ID',
  pumpCountryCode: 'Country Code',
  pumpLocation: 'Location Name',
  pumpTechnicalLocation: 'Technical Location Name',
  pumpName: 'Pump Name',
  pumpSensorsSerialNumber0: 'Serial Number',
  pumpSensorsId0: 'Sensor ID',
  pumpType: 'Type',
  pumpTypeSeries: 'Type Series',
  pumpSize: 'Type Size',
  pumpApplication: 'Application',
  pumpRatedSpeedPump: 'Rated Speed Pump (rpm)',
  pumpRatedSpeedMotor: 'Rated Speed Motor (rpm)',
  pumpVariableSpeed: 'Variable Speed',
  pumpRatedPower: 'Rated Power (kW)',
  pumpFlowRate: 'Flow (m³/h)',
  pumpRatedHead: 'Rated Head (m)',
  pumpNumberOfBlades: 'Blades',
  pumpNumberOfStages: 'Stages',
  pumpNumberOfEntries: 'Entries',
  pumpId: 'Pump ID'
}

interface Props {
  fields: string[]
  getValue: (field: string) => string
  clipboardRef: React.RefObject<HTMLDivElement>
}

export const PumpInfoClipboardTable: React.FC<Props> = ({
  fields,
  getValue,
  clipboardRef
}) => {
  return (
    <div ref={clipboardRef} style={{ display: 'none', whiteSpace: 'pre' }}>
      <table>
        <tbody>
          {fields.map((field) => (
            <tr key={field}>
              <td>{labelMap[field] ?? field}:</td>
              <td>{getValue(field)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
