import {
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_DensityState,
  MRT_ExpandedState,
  MRT_GroupingState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState
} from 'material-react-table'
import { reportedByColumnId } from '../../columnHeaders/definitions/useReportedByColumnHeader'
import { countryCodeColumnId } from '../../columnHeaders/definitions/useCountryCodeColumnHeader'
import { organizationNameColumnId } from '../../columnHeaders/definitions/useOrganizationNameColumnHeader'
import { pumpColumnId } from '../../columnHeaders/definitions/usePumpColumnHeader'
import { locationColumnId } from '../../columnHeaders/definitions/useLocationColumnHeader'
import { failureTypeColumnId } from '../../columnHeaders/definitions/useFailureTypeColumnHeader'
import { currentStateColumnId } from '../../columnHeaders/definitions/useCurrentStateColumnHeader'
import { highestStateColumnId } from '../../columnHeaders/definitions/useHighestStateColumnHeader'
import { assigneeColumnId } from '../../columnHeaders/definitions/useAssigneeColumnHeader'
import { firstOccurrenceColumnId } from '../../columnHeaders/definitions/useFirstOccurrenceColumnHeader'
import { lastOccurrenceColumnId } from '../../columnHeaders/definitions/useLastOccurrenceColumnHeader'
import { tagsColumnId } from '../../columnHeaders/definitions/useTagsColumnHeader'
import { externalLinkColumnId } from '../../columnHeaders/definitions/useExternalLinkColumnHeader'
import { isMutedColumnId } from '../../columnHeaders/definitions/useMutedColumnHeader'
import { acknowledgedColumnId } from '../../columnHeaders/definitions/useAcknowledgedColumnHeader'
import { openDetailsColumnId } from '../../columnHeaders/definitions/useOpenDetailsColumnHeader'

interface IInitialState {
  columnOrder: MRT_ColumnOrderState
  columnFilters: MRT_ColumnFiltersState
  columnVisibility: MRT_VisibilityState
  density: MRT_DensityState
  globalFilter: string | undefined
  showGlobalFilter: boolean
  showColumnFilters: boolean
  sorting: MRT_SortingState
  grouping: MRT_GroupingState
  pagination: MRT_PaginationState
  expanded: MRT_ExpandedState
  columnPinning: MRT_ColumnPinningState
  hideSpecificTags: boolean
}

export const getInitialState = (): IInitialState => {
  return {
    columnOrder: [
      openDetailsColumnId,
      countryCodeColumnId,
      organizationNameColumnId,
      pumpColumnId,
      locationColumnId,
      reportedByColumnId,
      failureTypeColumnId,
      currentStateColumnId,
      highestStateColumnId,
      assigneeColumnId,
      firstOccurrenceColumnId,
      lastOccurrenceColumnId,
      tagsColumnId,
      externalLinkColumnId,
      isMutedColumnId,
      acknowledgedColumnId
    ],
    columnFilters: [],
    columnVisibility: {},
    density: 'compact',
    globalFilter: undefined,
    showGlobalFilter: true,
    showColumnFilters: true,
    sorting: [],
    grouping: [],
    pagination: {
      pageIndex: 0,
      pageSize: 25
    },
    expanded: {
      all: false
    },
    columnPinning: {},
    hideSpecificTags: true
  }
}
