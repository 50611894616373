import { MRT_ColumnDef } from 'material-react-table'
import { Comment } from '../Contexts/types'
import React, { useMemo } from 'react'
import { formatDateTimeString } from '../utils/formatDateString'
import { Box, Typography } from '@mui/material'
import {
  mediumFontSize,
  smallFontSize
} from '../FailuresList/columnHeaders/cellFontSizes'

export const useCommentsTableHeader = (
  comments: Comment[]
): MRT_ColumnDef<Comment>[] => {
  const selectableAuthors = useMemo<string[]>(() => {
    const allAuthors = comments.map((comment) =>
      comment.author ? comment.author : '-'
    )

    const distinctAuthors = allAuthors.filter(
      (item, index) => allAuthors.indexOf(item) === index
    )

    return distinctAuthors.sort((a, b) => (a > b ? 1 : -1))
  }, [comments])

  return useMemo<MRT_ColumnDef<Comment>[]>(() => {
    return [
      {
        id: 'commentId',
        header: 'ID',
        accessorKey: 'id',
        Edit: () => undefined
      },
      {
        id: 'created',
        header: 'Timestamp',
        accessorKey: 'created',
        enableEditing: false,
        enableColumnFilter: false,
        size: 50,
        filterVariant: 'datetime-range',
        enableColumnActions: false,
        Edit: () => undefined,
        Cell: ({ row }) => {
          const comment = row.original
          const updatedAt =
            comment.changed === undefined
              ? undefined
              : formatDateTimeString(new Date(comment.changed))
          return (
            <Box>
              <Typography
                variant={'body2'}
                key={`detailPanelComments${comment.id}Created`}
              >
                {formatDateTimeString(new Date(comment.created))}
              </Typography>
              {updatedAt && (
                <Typography
                  variant={'body2'}
                  fontSize={smallFontSize}
                  key={`detailPanelComments${comment.id}Updated`}
                >
                  {updatedAt} (Updated)
                </Typography>
              )}
            </Box>
          )
        }
      },
      {
        header: 'Author',
        accessorKey: 'author',
        enableEditing: false,
        size: 100,
        filterSelectOptions: selectableAuthors,
        filterVariant: 'multi-select',
        enableColumnActions: false,
        Edit: () => undefined,
        Cell: ({ row }) => {
          const author = row.original.author
          const mailSegments = author.split('@')

          if (mailSegments.length === 1) {
            const name = mailSegments[0]
            return <div>{name}</div>
          }

          const name = mailSegments[0]
          const domain = mailSegments[1]
          return (
            <Box key={`detailPanelComments${row.original.id}Author`}>
              <Typography variant='body2' fontSize={mediumFontSize}>
                {name}
              </Typography>
              <Typography variant='body2' fontSize={smallFontSize}>
                {domain}
              </Typography>
            </Box>
          )
        }
      },
      {
        header: 'Comment',
        accessorKey: 'text',
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableGlobalFilter: false,
        enableColumnActions: false,
        grow: true,
        size: 500,
        Cell: ({ row }) => {
          const comment = row.original

          return (
            <Box>
              <Typography
                key={`detailPanelComments${comment.id}Text`}
                variant={'body2'}
                sx={{
                  maxHeight: '400px',
                  overflow: 'auto',
                  whiteSpace: 'pre-line'
                }}
              >
                {comment.text}
              </Typography>
            </Box>
          )
        }
      }
    ]
  }, [selectableAuthors])
}
