import React, { useMemo } from 'react'
import { FailuresList } from './failuresList'
import { FailuresListEntry } from './models/failuresListEntry'
import { FailureListTag } from './models/failureListTag'
import { FailureListTagType } from './models/failureListTagType'
import { useFetchCases } from '../Contexts/CasesContext'
import { CaseListEntry } from '../Contexts/types'
import { generateLinkToMDC } from '../utils/generateLinkToMDC'
export const FailureListContainer: React.FC = () => {
  const { isLoading, refetchCases, casesData, isRefetching, dataUpdatedAt } =
    useFetchCases()
  const cases = useMemo(() => casesData?.cases || [], [casesData?.cases])

  const data = useMemo<FailuresListEntry[]>(() => {
    return mapCaseListEntriesToFailuresListEntries(cases)
  }, [cases])

  return (
    <div>
      <FailuresList
        data={data}
        refetchCases={refetchCases}
        isLoading={isLoading}
        isRefetching={isRefetching}
        dataUpdatedAt={dataUpdatedAt}
      />
    </div>
  )
}

const mapCaseListEntriesToFailuresListEntries = (
  caseListEntries: CaseListEntry[]
): FailuresListEntry[] => {
  const entries: FailuresListEntry[] = []

  caseListEntries.forEach((caseListEntry) => {
    const failuresListEntries =
      mapCaseListEntryToFailuresListEntries(caseListEntry)
    entries.push(...failuresListEntries)
  })

  return entries
}

const mapCaseListEntryToFailuresListEntries = (
  caseListEntry: CaseListEntry
): FailuresListEntry[] => {
  const mapTags = (): FailureListTag[] => [
    ...caseListEntry.tags.map((tag) => ({
      value: tag,
      type: FailureListTagType.user
    })),
    ...caseListEntry.guardTags.map((guardTag) => ({
      value: guardTag,
      type: FailureListTagType.guard
    }))
  ]

  return caseListEntry.failures.map((failure) => ({
    externalLinks: [
      {
        url: generateLinkToMDC(
          caseListEntry.organizationId,
          caseListEntry.pumpId
        ),
        label: 'MD Cockpit'
      }
    ],
    countryCode: caseListEntry.pumpCountryCode,
    organizationName: caseListEntry.organizationName || '',
    failureType: failure.failureType,
    currentState: failure.failureState,
    highestFailureState: failure.highestFailureState,
    counterSinceLastAcknowledgement: 0, // Assuming a default value
    firstOccurrence: new Date(failure.firstOccurrence),
    lastOccurrence: new Date(failure.lastOccurrence),
    pumpName: caseListEntry.pumpName,
    pumpSerialNumber: caseListEntry.pumpSerialNumber,
    pumpId: caseListEntry.id,
    location: {
      location: caseListEntry.pumpLocation,
      technicalLocation: caseListEntry.pumpTechnicalLocation
    },
    assignee: caseListEntry.assignee,
    isAcknowledged: failure.acknowledged,
    mutedUntil:
      failure.mutedUntil === undefined
        ? undefined
        : new Date(failure.mutedUntil),
    tags: mapTags(),
    reportedBy: [
      {
        name: 'Guard',
        serialNumber: caseListEntry.pumpSensorsSerialNumber_0
      }
    ]
  }))
}
