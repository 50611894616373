import React, { FC } from 'react'
import './alarmCardActions.sass'
import {
  Failure,
  UpdatedFailureAcknowledgementData
} from '../../../../../Contexts/types'
import { MutingSwitch } from '../../../../../MutingSwitch/MutingSwitch'
import { useCaseContext } from '../../../../../Contexts'
import { SwitchType } from '../../../../../utils/SwitchType'
import { AcknowledgmentSwitchDetailView } from '../../../../../AcknowledgmentSwitch/AcknowledgmentSwitchDetailView'

interface AlarmCardActionsProps {
  failure: Failure
  caseId: string
  updateFailureAcknowledgement: (
    acknowledgement: UpdatedFailureAcknowledgementData
  ) => void
}

export const AlarmCardActions: FC<AlarmCardActionsProps> = ({
  failure,
  caseId
}) => {
  const { muteFailure, unmuteFailure, updateFailureAcknowledgement } =
    useCaseContext()

  const onAcknowledged = async (acknowledged: boolean): Promise<boolean> => {
    return updateFailureAcknowledgement({
      acknowledged: acknowledged,
      caseId: caseId,
      failureType: failure.failureType
    })
  }

  return (
    <div className='alarm-card-actions'>
      <AcknowledgmentSwitchDetailView
        caseId={caseId}
        failureType={failure.failureType}
        acknowledged={failure.acknowledged}
        onAcknowledged={onAcknowledged}
      />
      <MutingSwitch
        caseId={caseId}
        mutedUntil={
          failure.mutedUntil === undefined
            ? undefined
            : new Date(failure.mutedUntil)
        }
        failureType={failure.failureType}
        onMute={muteFailure}
        onUnmute={unmuteFailure}
        mutingSwitchType={SwitchType.detailView}
      />
    </div>
  )
}
