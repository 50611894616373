import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Tooltip, Typography } from '@mui/material'
import React from 'react'
import { mediumFontSize } from '../cellFontSizes'

export const organizationNameColumnId = 'organizationName'

export const useOrganizationNameColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: organizationNameColumnId,
      header: 'Organization',
      accessorKey: 'organizationName',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableColumnFilterModes: false,
      enableResizing: true,
      enableGlobalFilter: true,
      filterVariant: 'text',
      minSize: 100,
      maxSize: 200,
      aggregationFn: 'unique',
      filterFn: 'contains',
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const organizationName = row.original.organizationName
        return (
          <Tooltip title={organizationName}>
            <Typography
              className='cell-organization'
              variant='body2'
              fontSize={mediumFontSize}
            >
              {organizationName}
            </Typography>
          </Tooltip>
        )
      }
    }
  }
