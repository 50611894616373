import React from 'react'
import { PumpInfoPanel } from './pumpInfoPanel'
import { Grid, Typography, Box } from '@mui/material'
import { UrlsList } from '../urlsList'
import { Attribute, Case } from '../../Contexts/types'
import './pumpInfoPanelContainer.sass'
import { usePumpInfoCopyController } from './CopyPumpInfoPanelFields/useCopyPumpInfoController'
import { CopyPumpInfoButtons } from './CopyPumpInfoPanelFields/CopyPumpInfoButtons'
import { PumpInfoClipboardTable } from './CopyPumpInfoPanelFields/PumpInfoClipboardTable'

interface pumpInfoPanelContainerProps {
  activeCase: Case
}

export const PumpInfoPanelContainer: React.FC<pumpInfoPanelContainerProps> = ({
  activeCase
}) => {
  const {
    selectedFields,
    setSelectedFieldGroup,
    pumpDetailsClipboardElRef,
    getValue
  } = usePumpInfoCopyController(activeCase)

  const organizationId = getAttributeValueByName(
    activeCase.attributes,
    'organizationId'
  )

  const pumpId = getAttributeValueByName(activeCase.attributes, 'pumpId')

  return (
    <div>
      <div className='pump-info'>
        <div className='pump-info__header'>
          <CopyPumpInfoButtons setSelectedFieldGroup={setSelectedFieldGroup} />
        </div>

        <PumpInfoPanel pump={activeCase} />

        <Grid item sm={12}>
          <Box mt={1}>
            <UrlsList
              urls={activeCase.urls}
              organizationId={organizationId}
              pumpId={pumpId}
            />
          </Box>
        </Grid>

        <PumpInfoClipboardTable
          fields={selectedFields}
          getValue={getValue}
          clipboardRef={pumpDetailsClipboardElRef}
        />
      </div>
    </div>
  )
}

function getAttributeValueByName(
  attributes: Attribute[],
  attributeName: string
): string | null {
  return (
    attributes.find((attribute) => attribute.key === attributeName)?.value ||
    null
  )
}
