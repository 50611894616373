import React from 'react'
import IconButton from '@mui/material/IconButton'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Box, TextField } from '@mui/material'
import { FormButton } from '../../../FormButton'
import { Case } from '../../../Contexts/types'
import { useCaseDetailsContentDescription } from './useCaseDetailsContentDescription'
import { DebugBorder } from '../../../utils/debugBorder'
import './CaseDetailsContentDescription.sass'
import { SectionTitle } from '../../sectionTitle'

export interface CaseDetailsContentDescriptionProps {
  activeCase: Case
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}

export const CaseDetailsContentDescription: React.FC<
  CaseDetailsContentDescriptionProps
> = (props) => {
  const { activeCase } = props
  const {
    editMode,
    editDescription,
    handleCancel,
    handleFieldChange,
    handleSaveDescription,
    handleEditClick,
    isSaveDescriptionDisabled
  } = useCaseDetailsContentDescription(props)

  const multilineText = activeCase.description
    ? activeCase.description
        .trim()
        .split('\n')
        .map((t, index) => {
          return (
            <p key={index} className='case-details-content-description-read'>
              {t}
            </p>
          )
        })
    : ''

  return (
    <DebugBorder>
      <Box className='case-details-content-description-label' display={'flex'}>
        <SectionTitle title={'Description'} />

        {!editMode && (
          <IconButton
            className=''
            onClick={handleEditClick}
            style={{ marginLeft: '0px' }}
          >
            <ModeEditIcon
              sx={{ color: '#0080f0', width: '16px', height: '16px' }}
            />
          </IconButton>
        )}
      </Box>
      {!editMode ? (
        <Box
          sx={{ maxHeight: '200px', overflow: 'auto', marginBottom: '10px' }}
        >
          {multilineText}
        </Box>
      ) : (
        <Box>
          <TextField
            autoFocus
            value={editDescription}
            onChange={handleFieldChange}
            multiline
            rows={4}
            fullWidth
            margin='normal'
          />
          <FormButton
            disabled={isSaveDescriptionDisabled()}
            onClick={handleSaveDescription}
            variant='accept'
          />
          <FormButton onClick={handleCancel} variant='decline' />
        </Box>
      )}
    </DebugBorder>
  )
}
