import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { getFailureStateBackgroundColor } from '../../../utils/getFailureStateColor'
import { sortingFailureState } from '../../sortFunctions/sortingFailureState'
import { Box } from '@mui/material'

export const highestStateColumnId = 'highestFailureState'

export const useHighestStateColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const distinctHighestStates = data.reduce<string[]>((acc, failureEntry) => {
      if (
        failureEntry.highestFailureState &&
        !acc.includes(failureEntry.highestFailureState)
      ) {
        acc.push(failureEntry.highestFailureState)
      }
      return acc
    }, [])

    return distinctHighestStates.map((item) =>
      item.replaceAll('FAILURESTATE_', '')
    )
  }, [data])

  return {
    id: highestStateColumnId,
    header: 'Highest State',
    accessorKey: 'highestFailureState',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    enableGlobalFilter: false,
    enableResizing: false,
    minSize: 80,
    maxSize: 80,
    aggregationFn: 'unique',
    sortUndefined: 'last',
    sortingFn: sortingFailureState,
    Cell: (data) => {
      const value = data.cell.getValue<string>()

      if (!value) {
        return <div> </div>
      }

      return (
        <Box
          className='cell-highest-state failure-state-box'
          style={{
            backgroundColor: `${getFailureStateBackgroundColor(value)}`
          }}
        >
          {value.replaceAll('FAILURESTATE_', '')}
        </Box>
      )
    }
  }
}
