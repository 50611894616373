import { Box, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { SectionTitle } from '../CaseDetails/sectionTitle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

interface ICommentsTableTopToolbarCustomActionsProps {
  onCreate: () => void
  isReadOnly: boolean
}

export const CommentsTableTopToolbarCustomActions: React.FC<
  ICommentsTableTopToolbarCustomActionsProps
> = (props: ICommentsTableTopToolbarCustomActionsProps) => {
  return (
    <Box display={'flex'}>
      <SectionTitle title={'Comments'} />
      {!props.isReadOnly && (
        <Tooltip title={'Create new comment'}>
          <IconButton onClick={props.onCreate} size={'small'}>
            <AddCircleOutlineIcon
              sx={{
                padding: '0',
                color: '#0080f0'
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
