import { Comment } from '../Contexts/types'
import {
  MaterialReactTable,
  MRT_TableInstance,
  useMaterialReactTable
} from 'material-react-table'
import React, { useMemo } from 'react'
import { CommentsTableDialog } from './CommentsTableDialog'
import { useCommentsTableHeader } from './useCommentsTableHeader'
import { CommentsTableRowActions } from './CommentsTableRowActions'
import { CommentsTableTopToolbarCustomActions } from './CommentsTableTopToolbarCustomActions'

interface ICommentsTableProps {
  comments: Comment[]
  caseId: string
  isReadOnly?: boolean
  addComment?: (caseId: string, author: string, text: string) => Promise<void>
  updateComment?: (
    caseId: string,
    author: string,
    text: string,
    commentId: string
  ) => Promise<void>
  removeComment?: (caseId: string, commentId: string) => Promise<void>
}

export const CommentsTable: React.FC<ICommentsTableProps> = (
  props: ICommentsTableProps
) => {
  const isReadOnly = useMemo<boolean>(() => {
    return props.isReadOnly ?? true
  }, [props.isReadOnly])

  const areCommentsAvailable = useMemo<boolean>(() => {
    return props.comments.length > 0
  }, [props.comments.length])

  const columns = useCommentsTableHeader(props.comments)

  const table: MRT_TableInstance<Comment> = useMaterialReactTable({
    columns: columns,
    data: props.comments,
    enableEditing: !isReadOnly,
    editDisplayMode: 'modal',
    createDisplayMode: 'modal',
    positionCreatingRow: 'top',
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableTableHead: areCommentsAvailable,
    enableTableFooter: areCommentsAvailable,
    enablePagination: areCommentsAvailable,
    enableBottomToolbar: areCommentsAvailable,
    enableStickyHeader: false,
    enableStickyFooter: false,
    enableExpanding: false,
    enableRowPinning: false,
    enableGrouping: false,
    enableDensityToggle: false,
    enableColumnPinning: false,
    enableColumnFilterModes: false,
    enableBatchRowSelection: false,
    enableFilters: true,
    enableSortingRemoval: false,
    enableHiding: false,
    enableRowActions: !isReadOnly,
    enableColumnResizing: false,
    enableFilterMatchHighlighting: false,
    enableFullScreenToggle: false,
    enableTopToolbar: true,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'popover',
    positionGlobalFilter: 'right',
    state: {
      density: 'compact'
    },
    initialState: {
      sorting: [
        {
          id: 'created',
          desc: true
        }
      ],
      columnVisibility: {
        commentId: false
      }
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Edit',
        size: 40
      }
    },
    renderRowActions: ({ row, table }) => {
      return <CommentsTableRowActions onEdit={() => table.setEditingRow(row)} />
    },
    renderEditRowDialogContent: ({ internalEditComponents, row, table }) => {
      return (
        <CommentsTableDialog
          comment={row.original}
          table={table}
          addComment={props.addComment}
          updateComment={props.updateComment}
          removeComment={props.removeComment}
          caseId={props.caseId}
        />
      )
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <CommentsTableTopToolbarCustomActions
        onCreate={() => table.setCreatingRow(true)}
        isReadOnly={isReadOnly}
      />
    ),
    muiEditRowDialogProps: () => {
      return {
        open: true,
        maxWidth: 'sm'
      }
    },
    muiTablePaperProps: {
      sx: {
        marginTop: '0px',
        border: '0px solid rgba(81, 81, 81, .5)',
        boxShadow: 'none'
      }
    },
    muiDetailPanelProps: () => ({
      sx: {
        backgroundColor: 'rgba(255,210,244,0.1)'
      }
    }),
    muiTableBodyRowProps: {
      style: {
        padding: '0'
      }
    },
    muiTableBodyCellProps: {
      style: {
        verticalAlign: 'top'
      }
    }
  })

  return (
    <div data-testid={`comments-list-table-${props.caseId}`}>
      <MaterialReactTable table={table} />
    </div>
  )
}
