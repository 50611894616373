interface IUpdateFailureAcknowledgementRequestProps {
  token: string
  casesUrl: string
  acknowledged: boolean
  caseId: string
  failureType: string
}

export const updateFailureAcknowledgementRequest = async (
  props: IUpdateFailureAcknowledgementRequestProps
): Promise<boolean> => {
  const { acknowledged, caseId, casesUrl, failureType, token } = props
  try {
    const response = await fetch(
      `${casesUrl}/${caseId}/failures/${failureType}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify({
          acknowledged: acknowledged
        })
      }
    )

    if (!response.ok) {
      throw new Error('Failed to update failure acknowledgement')
    }

    const acknowledgedResponse = JSON.parse(await response.text()).acknowledged

    if (acknowledgedResponse === undefined) {
      throw new Error('Failed to update failure acknowledgement')
    }

    return acknowledgedResponse as boolean
  } catch (error) {
    console.error('Error updating failure acknowledgement: ', error)

    return acknowledged
  }
}
