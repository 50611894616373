import { Grid } from '@mui/material'
import { Assignee } from '../../Assignee'
import React from 'react'
import './caseDetailsContent.sass'
import { CaseDetailsContentTitle } from './Title/caseDetailsContentTitle'
import { useCaseDetailsContent } from './useCaseDetailsContent'
import { Case } from '../../Contexts/types'
import { CaseDetailsContentDescription } from './Description/CaseDetailsContentDescription'
import { AlarmCardsSection } from './AlarmCardSection/AlarmCardsSection'
import { HistorySection } from './HistorySection/historySection'
import { CaseDetailsContentTags } from './Tags/caseDetailsContentTags'
import { CaseDetailsContentGuardTags } from './Tags/caseDetailsContentGuardTags'
import LastSynchronizationLabel from '../../LastSynchronizationLabel/lastSynchronizationLabel'
import { CommentsTable } from '../../CommentsTable/CommentsTable'
import { useCaseContext } from '../../Contexts'

interface CaseDetailsContentProps {
  activeCase: Case
  caseSyncedAt: string
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}

export const CaseDetailsContent: React.FC<CaseDetailsContentProps> = (
  props
) => {
  const { addComment, updateComment, removeComment } = useCaseContext()
  const { activeCase, setSnackbarMessage } = props
  const { sortedComments, sortedHistoryEntries } =
    useCaseDetailsContent(activeCase)

  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <LastSynchronizationLabel lastSyncAt={props.caseSyncedAt} />
        <CaseDetailsContentTitle title={activeCase.title} />
      </Grid>
      <Grid item xs={3}>
        <Assignee assignee={activeCase.assignee} />
      </Grid>
      <Grid item xs={9}>
        <CaseDetailsContentTags
          activeCase={activeCase}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Grid>
      <Grid item xs={3}>
        <CaseDetailsContentGuardTags activeCase={activeCase} />
      </Grid>
      <Grid item xs={12}>
        <CaseDetailsContentDescription
          activeCase={activeCase}
          setSnackbarMessage={setSnackbarMessage}
        />
      </Grid>
      <Grid item xs={12}>
        <AlarmCardsSection activeCase={activeCase} />
      </Grid>
      <Grid item xs={12}>
        <CommentsTable
          comments={sortedComments}
          isReadOnly={false}
          caseId={activeCase.id}
          addComment={async (caseId, author, text) =>
            await addComment({
              author,
              caseId,
              text
            })
          }
          updateComment={async (caseId, author, text, commentId) =>
            await updateComment({
              author,
              caseId,
              text,
              commentId
            })
          }
          removeComment={async (caseId, commentId) =>
            await removeComment({
              caseId,
              commentId
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <HistorySection historyEntries={sortedHistoryEntries ?? []} />
      </Grid>
    </Grid>
  )
}
