import React, { useEffect, useMemo } from 'react'
import './failuresList.sass'
import {
  MaterialReactTable,
  MRT_TableInstance,
  useMaterialReactTable
} from 'material-react-table'
import { FailuresListEntry } from './models/failuresListEntry'
import { useFailureListState } from './state/useFailureListState'
import { useFailureListColumns } from './columnHeaders/useFailureListColumns'
import { ToolbarCustomActions } from './components/toolbarCustomActions'
import { CaseListEntry } from '../Contexts/types'
import { QueryObserverResult } from 'react-query'
import { ExpandableDetailPanel } from './components/ExpandableDetailPanel'

interface IFailuresListProps {
  data: FailuresListEntry[]
  refetchCases: () => Promise<
    QueryObserverResult<{ cases: CaseListEntry[] }, unknown>
  >
  isLoading: boolean
  isRefetching: boolean
  dataUpdatedAt: number
}

const tagsToIgnore = ['TEST / DEMO', 'SENSOR DEFECT']

export const FailuresList: React.FC<IFailuresListProps> = (props) => {
  useEffect(() => {
    document.title = 'IM | Issues'
  }, [])

  const {
    allowNextSortingResetRef,
    columnOrder,
    setColumnOrder,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    density,
    setDensity,
    globalFilter,
    setGlobalFilter,
    showGlobalFilter,
    setShowGlobalFilter,
    showColumnFilters,
    setShowColumnFilters,
    sorting,
    setSorting,
    grouping,
    setGrouping,
    pagination,
    setPagination,
    expanded,
    setExpanded,
    columnPinning,
    setColumnPinning,
    hideSpecificTags,
    setHideSpecificTags
  } = useFailureListState()

  const filteredData = useMemo<FailuresListEntry[]>(() => {
    if (!hideSpecificTags) {
      return props.data
    }

    return props.data.filter((entry) => {
      return !entry.tags.some((tag) =>
        tagsToIgnore.some((tagToIgnore) =>
          tag.value.toUpperCase().includes(tagToIgnore.toUpperCase())
        )
      )
    })
  }, [props.data, hideSpecificTags])

  const columns = useFailureListColumns(filteredData)

  const table: MRT_TableInstance<FailuresListEntry> = useMaterialReactTable({
    columns: columns,
    data: filteredData,
    enableRowSelection: false,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: 'contains',
    enableStickyHeader: false,
    enableStickyFooter: false,
    enableExpanding: false,
    enableRowPinning: false,
    enableGrouping: false,
    enableDensityToggle: false,
    enableColumnPinning: false,
    enableColumnFilterModes: false,
    enableSortingRemoval: true,
    enableHiding: true,
    autoResetPageIndex: false,
    enableColumnResizing: true,
    enableFilterMatchHighlighting: false,
    enableFullScreenToggle: false,
    columnFilterDisplayMode: 'popover',
    positionGlobalFilter: 'right',
    layoutMode: 'grid',
    isMultiSortEvent: () => true,
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onDensityChange: setDensity,
    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onShowGlobalFilterChange: setShowGlobalFilter,
    onSortingChange: (newSorting) => {
      const isReset = newSorting.length === 0 && sorting.length > 0

      if (isReset && !allowNextSortingResetRef.current) {
        return
      }

      if (isReset && allowNextSortingResetRef.current) {
        allowNextSortingResetRef.current = false
      }

      setSorting(newSorting)
    },
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    onColumnPinningChange: setColumnPinning,
    state: {
      columnOrder,
      columnFilters,
      columnVisibility,
      density,
      globalFilter,
      showGlobalFilter,
      showColumnFilters,
      sorting,
      grouping,
      pagination,
      expanded,
      columnPinning
    },
    muiFilterTextFieldProps: {
      sx: { m: '0', p: '0', width: '100%' },
      variant: 'outlined'
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',

        transition: 'transform 0.2s'
      },
      'data-testid': `ExpandDetailPanelButton${row.original.pumpId}${row.original.failureType.toUpperCase()}`
    }),
    renderDetailPanel: ({ row }) => {
      return <ExpandableDetailPanel entry={row.original} />
    },
    renderTopToolbarCustomActions: () => (
      <ToolbarCustomActions
        refreshAction={async () => {
          await props.refetchCases()
        }}
        resetFilterAndSorting={handleResetFilterAndSorting}
        tagsToIgnore={tagsToIgnore}
        hideSpecificTags={hideSpecificTags}
        setHideSpecificTags={setHideSpecificTags}
        isRefetching={props.isRefetching}
        dataUpdatedAt={props.dataUpdatedAt}
      />
    )
  })

  const handleResetFilterAndSorting = React.useCallback(() => {
    allowNextSortingResetRef.current = true
    table.resetColumnFilters()
    table.resetSorting(true)
  }, [allowNextSortingResetRef, table])

  return (
    <div data-testid='failures-list-table'>
      <MaterialReactTable table={table} />
    </div>
  )
}
