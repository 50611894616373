import React, { createContext, ReactNode, useCallback, useContext } from 'react'

import { useQuery } from 'react-query'
import { Config, useConfig } from '../hooks/useConfig'
import { useAuth } from './AuthContext'
import {
  Case,
  IPostTags,
  UpdatedCaseData,
  UpdatedFailureAcknowledgementData,
  User
} from './types'
import { useParams } from 'react-router-dom'
import {
  muteFailureRequest,
  unmuteFailureRequest
} from './api/muteFailuresRequest'
import { updateFailureAcknowledgementRequest } from './api/updateAcknowledgementRequest'

//TODO (Justice): Error handling is inconsistent within this file (throw error, console.log, return empty array, etc.)

type CaseContextType = {
  currentCase: Case | undefined
  caseSyncedAt: string
  loadCase: () => Promise<void>
  updateCase: (updatedCaseData: UpdatedCaseData) => Promise<Case | any>
  isLoading: boolean
  isError: boolean
  postTags: (payload: IPostTags) => Promise<void>
  removeTagFromCase: (payload: IPostTags) => Promise<void>
  postUrl: (payload: {
    caseId: string
    url: string
    label: string
  }) => Promise<void>
  addComment: (payload: {
    caseId: string
    author: string
    text: string
  }) => Promise<void>
  updateComment: (payload: {
    caseId: string
    author: string
    text: string
    commentId: string
  }) => Promise<void>
  removeComment: (payload: {
    caseId: string
    commentId: string
  }) => Promise<void>
  removeUrl: (payload: { caseId: string; urlId: string }) => Promise<void>
  fetchAssignableUsers: () => Promise<User[]>
  getPumpImage: (
    pumpId: string | null,
    organizationId: string | null
  ) => Promise<string>
  updateAssignee: (assignee: string) => Promise<void>
  deleteAssignee: () => Promise<void>
  updateFailureAcknowledgement: (
    updatedFailureAcknowledgementData: UpdatedFailureAcknowledgementData
  ) => Promise<boolean>
  muteFailure: (failureType: string, muteUntil?: Date) => Promise<void>
  unmuteFailure: (failureType: string) => Promise<void>
}

const CaseContext: React.Context<CaseContextType | undefined> = createContext<
  CaseContextType | undefined
>(undefined)

type CaseProviderProps = {
  children: ReactNode
  config: Config
}

export const CaseProvider: React.FC<CaseProviderProps> = ({
  children,
  config
}) => {
  const auth = useAuth()
  const { caseId } = useParams()

  if (!caseId) {
    throw Error('Invalid case id')
  }

  const { caseDetailsData, isError, isLoading, refetchCase } =
    useFetchCase(caseId)

  const CASES_URL = config?.API_HOST_URL + '/cases'

  const loadCase = async (): Promise<void> => {
    await refetchCase()
  }

  const updateCase = async (updatedCaseData: UpdatedCaseData) => {
    try {
      const response = await fetch(`${CASES_URL}/${updatedCaseData.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        },
        body: JSON.stringify({
          id: updatedCaseData.id,
          title: updatedCaseData.title,
          assignee: updatedCaseData.assignee,
          status: updatedCaseData.status,
          description: updatedCaseData.description
        })
      })

      if (!response.ok) {
        throw new Error('Failed to update case')
      }

      await refetchCase()
    } catch (error) {
      console.error('Error updating case:', error)
      // throw error;
    }
  }

  const postTags = async ({ id, tag }: IPostTags) => {
    try {
      // TODO validation - only letters numbers and dash
      const response = await fetch(`${CASES_URL}/${id}/tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        },
        body: JSON.stringify({
          value: tag
        })
      })

      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Something went wrong')
      }
    } catch (e) {
      throw Error('Failed to add the tag')
    }
  }

  const removeTagFromCase = useCallback(
    async ({ id, tag }: IPostTags) => {
      try {
        const response = await fetch(
          `${CASES_URL}/${id}/tags/${encodeURIComponent(tag)}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: await auth!.getBearer()
            }
          }
        )
        if (response.ok) {
          await refetchCase()
        }
      } catch (error) {
        throw new Error('Failed to remove the tag')
      }
    },
    [CASES_URL, auth, refetchCase]
  )

  const addComment = async ({
    caseId,
    author,
    text
  }: {
    caseId: string
    author: string
    text: string
  }) => {
    try {
      const response = await fetch(`${CASES_URL}/${caseId}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        },
        body: JSON.stringify({
          author,
          text
        })
      })

      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      throw Error('Failed to add the comment')
    }
  }

  const updateComment = async ({
    caseId,
    commentId,
    author,
    text
  }: {
    caseId: string
    author: string
    commentId: string
    text: string
  }) => {
    try {
      const response = await fetch(
        `${CASES_URL}/${caseId}/comments/${commentId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: await auth!.getBearer()
          },
          body: JSON.stringify({
            author,
            text
          })
        }
      )
      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      throw Error('Failed to update the comment')
    }
  }

  const removeComment = async ({
    caseId,
    commentId
  }: {
    caseId: string
    commentId: string
  }) => {
    try {
      const response = await fetch(
        `${CASES_URL}/${caseId}/comments/${commentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: await auth!.getBearer()
          },
          body: JSON.stringify({
            caseId,
            commentId
          })
        }
      )

      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Error during DELETE request for comments')
      }
    } catch (error) {
      throw Error('Failed to remove the comment')
    }
  }

  const postUrl = async ({
    caseId,
    url,
    label
  }: {
    caseId: string
    url: string
    label: string
  }) => {
    try {
      const response = await fetch(`${CASES_URL}/${caseId}/urls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        },
        body: JSON.stringify({
          url,
          label
        })
      })

      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Something went wrong')
      }
    } catch (e) {
      throw new Error('Failed to add the url')
    }
  }

  const removeUrl = async ({
    caseId,
    urlId
  }: {
    caseId: string
    urlId: string
  }) => {
    try {
      const response = await fetch(`${CASES_URL}/${caseId}/urls/${urlId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        }
      })
      if (response.ok) {
        await refetchCase()
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      console.error('Failed to remove the url', error)
    }
  }

  const fetchAssignableUsers = async (): Promise<User[]> => {
    try {
      const response = await fetch(`${config?.API_HOST_URL}/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        }
      })

      const data = await response.json()
      return data.users
    } catch (error) {
      console.error('Error fetching assignable users:', error)
      return []
    }
  }

  const getPumpImage = async (
    pumpId: string | null,
    organizationId: string | null
  ): Promise<string> => {
    const IMAGE_URL = `${config?.API_HOST_URL}/pump-image/${organizationId}/${pumpId}`

    try {
      const response = await fetch(IMAGE_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        }
      })

      return await response.text()
    } catch (error) {
      console.error('Error fetching pump image:', error)
      return ''
    }
  }

  const updateAssignee = async (assignee: string): Promise<void> => {
    try {
      const response = await fetch(`${CASES_URL}/${caseId}/assignee`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: await auth!.getBearer()
        },
        body: JSON.stringify({
          assignee
        })
      })

      if (!response.ok) {
        throw new Error('Not successful response from set-assignee endpoint')
      }

      await refetchCase()
    } catch (error) {
      console.error('Error occurred during assignee setting', error)
      throw error
    }
  }

  const deleteAssignee = async (): Promise<void> => {
    try {
      const response = await fetch(`${CASES_URL}/${caseId}/assignee`, {
        method: 'DELETE',
        headers: {
          Authorization: await auth!.getBearer()
        }
      })

      if (!response.ok) {
        throw new Error('Not successful response from delete-assignee endpoint')
      }

      await refetchCase()
    } catch (error) {
      console.error('Error occurred during assignee deleting', error)

      throw error
    }
  }

  const updateFailureAcknowledgement = async (
    updatedFailureAcknowledgementData: UpdatedFailureAcknowledgementData
  ): Promise<boolean> => {
    const isAcknowledged = await updateFailureAcknowledgementRequest({
      token: await auth!.getBearer(),
      acknowledged: updatedFailureAcknowledgementData.acknowledged,
      caseId: updatedFailureAcknowledgementData.caseId,
      failureType: updatedFailureAcknowledgementData.failureType,
      casesUrl: CASES_URL
    })
    await refetchCase()

    return isAcknowledged
  }

  const muteFailure = async (
    failureType: string,
    muteUntil?: Date
  ): Promise<void> => {
    await muteFailureRequest(
      await auth!.getBearer(),
      CASES_URL,
      caseId,
      failureType,
      muteUntil
    )
    await refetchCase()
  }
  const unmuteFailure = async (failureType: string): Promise<void> => {
    await unmuteFailureRequest(
      await auth!.getBearer(),
      CASES_URL,
      caseId,
      failureType
    )
    await refetchCase()
  }

  const getCurrentCase = useCallback(() => caseDetailsData, [caseDetailsData])
  const currentCase = getCurrentCase()
  const caseSyncedAt = new Date().toISOString()

  return (
    <CaseContext.Provider
      value={{
        currentCase,
        caseSyncedAt,
        loadCase,
        updateCase,
        isLoading: isLoading,
        isError: isError,
        postTags,
        addComment,
        updateComment,
        removeComment,
        removeTagFromCase,
        postUrl,
        removeUrl,
        fetchAssignableUsers,
        getPumpImage,
        updateAssignee,
        deleteAssignee,
        updateFailureAcknowledgement,
        muteFailure,
        unmuteFailure
      }}
    >
      {children}
    </CaseContext.Provider>
  )
}

export const useCaseContext = () => {
  const context: CaseContextType | undefined = useContext(CaseContext)
  if (!context) {
    throw new Error('usePumpContext must be used within a PumpProvider')
  }
  return context
}

export const useFetchCase = (caseId: string) => {
  const auth = useAuth()
  const config = useConfig()
  const CASE_URL = config?.API_HOST_URL + '/cases/' + caseId

  async function fetchCase() {
    const response = await fetch(CASE_URL, {
      cache: 'no-cache',
      headers: {
        Authorization: await auth!.getBearer()
      }
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  const {
    data: responseData,
    isLoading,
    isError,
    refetch
  } = useQuery<Case>(['case', caseId], fetchCase, {
    enabled: !!config
  })

  return {
    caseDetailsData: responseData,
    isLoading,
    isError,
    refetchCase: refetch
  }
}
