import { MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'

export function getCellKey(
  caseId: string,
  failureType: string,
  columnName: string
): string {
  return `${caseId}-${failureType}-${columnName}`
}

export function getCellKeyFromRow(
  row: MRT_Row<FailuresListEntry>,
  columnName: string
): string {
  const entry = row.original
  return `${entry.pumpId}-${entry.failureType}-${columnName}`
}
