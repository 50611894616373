import {
  columnFiltersKey,
  columnOrderKey,
  columnPinningKey,
  columnVisibilityKey,
  densityKey,
  expandedKey,
  globalFilterKey,
  groupingKey,
  paginationKey,
  showColumnFiltersKey,
  showGlobalFilterKey,
  hideSpecificTagsKey,
  sortingKey
} from './localStorageKeys'
import { ISetFailuresStateActions } from './ISetFailuresStateActions'
import dayjs, { Dayjs } from 'dayjs'

interface ColumnFilter {
  id: string
  value: (string | Dayjs | null)[]
}

const parseStoredDate = (value: string | Dayjs | null): Dayjs | null => {
  if (!value) return null
  if (dayjs.isDayjs(value)) return value
  const parsedDate = dayjs(value)
  return parsedDate.isValid() ? parsedDate : null
}

const parseColumnFilters = (filters: ColumnFilter[]): ColumnFilter[] => {
  return filters.map((filter) =>
    (filter.id === 'firstOccurrence' || filter.id === 'lastOccurrence') &&
    Array.isArray(filter.value)
      ? { ...filter, value: filter.value.map(parseStoredDate) }
      : filter
  )
}

export const loadStateFromLocalStorage = (
  setActions: ISetFailuresStateActions
): void => {
  const stateSetters: Record<string, (value: any) => void> = {
    columnOrder: setActions.setColumnOrder,
    columnFilters: setActions.setColumnFilters,
    columnVisibility: setActions.setColumnVisibility,
    density: setActions.setDensity,
    globalFilter: setActions.setGlobalFilter,
    showGlobalFilter: setActions.setShowGlobalFilter,
    showColumnFilters: setActions.setShowColumnFilters,
    sorting: setActions.setSorting,
    grouping: setActions.setGrouping,
    pagination: setActions.setPagination,
    expanded: setActions.setExpanded,
    columnPinning: setActions.setColumnPinning,
    hideSpecificTags: setActions.setHideSpecificTags
  }

  const storageKeys = {
    columnOrder: columnOrderKey,
    columnFilters: columnFiltersKey,
    columnVisibility: columnVisibilityKey,
    density: densityKey,
    globalFilter: globalFilterKey,
    showGlobalFilter: showGlobalFilterKey,
    showColumnFilters: showColumnFiltersKey,
    sorting: sortingKey,
    grouping: groupingKey,
    pagination: paginationKey,
    expanded: expandedKey,
    columnPinning: columnPinningKey,
    hideSpecificTags: hideSpecificTagsKey
  }

  Object.entries(storageKeys).forEach(([stateKey, storageKey]) => {
    const storedValue = localStorage.getItem(storageKey)
    if (!storedValue) return

    let parsedValue = JSON.parse(storedValue)

    if (stateKey === 'columnFilters' && Array.isArray(parsedValue)) {
      parsedValue = parseColumnFilters(parsedValue)
    }

    stateSetters[stateKey]?.(parsedValue)
  })
}
