import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { FailureListExternalLink } from '../../models/failureListExternalLink'
import React from 'react'
import { Box, Chip } from '@mui/material'

export const externalLinkColumnId = 'externalLink'

export const useExternalLinkColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: externalLinkColumnId,
      header: 'Links',
      accessorKey: 'externalLinks',
      enableColumnDragging: false,
      enableColumnActions: false,
      enableGrouping: false,
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      enableGlobalFilter: false,
      enableColumnFilter: false,
      enableResizing: false,
      enableSorting: false,
      minSize: 100,
      maxSize: 100,
      aggregationFn: 'unique',
      AggregatedCell(props) {
        return (
          <div>
            {props.cell.getValue<string[]>().map((x) => {
              return <div>{x}</div>
            })}
          </div>
        )
      },
      Cell: (data) => {
        const links = data.cell.getValue<FailureListExternalLink[]>()
        return (
          <Box className='cell-links'>
            {links.map((link, index) => {
              return (
                <Box key={index} mb={1}>
                  <Chip
                    component='a'
                    href={link.url}
                    target='_blank'
                    rel='noreferrer'
                    label={link.label}
                    clickable
                    color='primary'
                    size='small'
                    variant='filled'
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white'
                      }
                    }}
                  />
                </Box>
              )
            })}
          </Box>
        )
      }
    }
  }
