import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { mediumFontSize, smallFontSize } from '../cellFontSizes'
import { getCellKeyFromRow } from './column-utils'

export const reportedByColumnId = 'reportedByColumnId'

export const useReportedByColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    return {
      id: reportedByColumnId,
      header: 'Reported by',
      accessorFn(originalRow) {
        return originalRow.reportedBy
          .map((x) => x.name + ' ' + (x.serialNumber ?? ''))
          .join(' ')
      },
      visibleInShowHideMenu: true,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableGrouping: true,
      enableColumnFilterModes: false,
      enableResizing: true,
      enableGlobalFilter: true,
      minSize: 60,
      maxSize: 120,
      filterFn: 'contains',
      Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
        const reportedBy = row.original.reportedBy
        if (reportedBy === undefined || reportedBy.length === 0) {
          return <></>
        }

        const cellKey = getCellKeyFromRow(row, reportedByColumnId)

        return (
          <Box className='cell-reported-by' key={cellKey}>
            {reportedBy.map((reporter) => {
              return (
                <Tooltip
                  key={`${cellKey}-${reporter.name}-${reporter.serialNumber}`}
                  title={
                    <Box>
                      <Typography variant='body2'>{reporter.name}</Typography>
                      {reporter.serialNumber && (
                        <Typography variant='body2' fontSize={11}>
                          SN: {reporter.serialNumber}
                        </Typography>
                      )}
                    </Box>
                  }
                >
                  <Box>
                    <Typography variant='body2' fontSize={mediumFontSize}>
                      {reporter.name}
                    </Typography>
                    {reporter.serialNumber && (
                      <Typography variant='body2' fontSize={smallFontSize}>
                        {reporter.serialNumber}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              )
            })}
          </Box>
        )
      }
    }
  }
