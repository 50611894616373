import { useCallback, useEffect, useState } from 'react'
import { Attribute, Case } from '../../../Contexts/types'
import { useCopyPumpInfoToClipboard } from './useCopyPumpInfoToClipboard'

export type FieldGroup = 'all' | 'c4c' | 'ca'

const FIELDS: Record<FieldGroup, string[]> = {
  all: [
    'organizationName',
    'customerId',
    'pumpCountryCode',
    'pumpLocation',
    'pumpTechnicalLocation',
    'pumpName',
    'pumpSensorsSerialNumber0',
    'pumpSensorsId0',
    'pumpType',
    'pumpTypeSeries',
    'pumpSize',
    'pumpApplication',
    'pumpRatedSpeedPump',
    'pumpRatedSpeedMotor',
    'pumpVariableSpeed',
    'pumpRatedPower',
    'pumpFlowRate',
    'pumpRatedHead',
    'pumpNumberOfBlades',
    'pumpNumberOfStages',
    'pumpNumberOfEntries',
    'pumpId'
  ],
  c4c: [],
  ca: [
    'organizationName',
    'customerId',
    'pumpLocation',
    'pumpTechnicalLocation',
    'pumpName',
    'pumpSensorsSerialNumber0',
    'pumpSensorsId0',
    'pumpTypeSeries',
    'pumpSize',
    'pumpRatedSpeedPump',
    'pumpRatedSpeedMotor',
    'pumpVariableSpeed',
    'pumpRatedPower'
  ]
}

FIELDS.c4c = [
  ...FIELDS.all.filter(
    (f) =>
      f !== 'pumpFlowRate' && f !== 'pumpRatedHead' && f !== 'pumpCountryCode'
  ),
  'link'
]

export const usePumpInfoCopyController = (activeCase: Case) => {
  const [selectedFieldGroup, setSelectedFieldGroup] =
    useState<FieldGroup | null>(null)

  const { pumpDetailsClipboardElRef, copyDetailsToClipboard } =
    useCopyPumpInfoToClipboard()

  const getValue = useCallback(
    (field: string): string => {
      if (field === 'organization') {
        return (
          getAttributeValueByName(activeCase.attributes, 'organizationName') ??
          ''
        )
      }

      if (field === 'link') {
        return window.location.href
      }

      const directValue = (activeCase as any)[field]
      if (directValue !== undefined) {
        return String(directValue)
      }

      return getAttributeValueByName(activeCase.attributes, field) ?? ''
    },
    [activeCase]
  )

  useEffect(() => {
    if (selectedFieldGroup) {
      copyDetailsToClipboard()
      setSelectedFieldGroup(null)
    }
  }, [selectedFieldGroup, copyDetailsToClipboard])

  let selectedFields: string[] = []
  if (selectedFieldGroup === 'all') {
    selectedFields = FIELDS.all
  } else if (selectedFieldGroup === 'c4c') {
    selectedFields = FIELDS.c4c
  } else if (selectedFieldGroup === 'ca') {
    selectedFields = FIELDS.ca
  }

  return {
    selectedFields,
    setSelectedFieldGroup,
    pumpDetailsClipboardElRef,
    getValue
  }
}

function getAttributeValueByName(
  attributes: Attribute[],
  name: string
): string | null {
  return attributes.find((a) => a.key === name)?.value ?? null
}
