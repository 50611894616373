import { Box, Stack, Switch, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Sync } from '@mui/icons-material'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import React from 'react'
import LastSynchronizationLabel from '../../LastSynchronizationLabel/lastSynchronizationLabel'

interface IToolbarCustomActionsProps {
  refreshAction: () => Promise<void>
  resetFilterAndSorting: () => void
  tagsToIgnore: string[]
  hideSpecificTags: boolean
  setHideSpecificTags: React.Dispatch<React.SetStateAction<boolean>>
  isRefetching: boolean
  dataUpdatedAt: number
}

export const ToolbarCustomActions: React.FC<IToolbarCustomActionsProps> = (
  props: IToolbarCustomActionsProps
) => {
  return (
    <Stack direction='row' spacing={1}>
      <Tooltip arrow title='Reset Filter / Sorting'>
        <IconButton
          onClick={() => props.resetFilterAndSorting()}
          data-testid='failure-table-reset-config-button'
        >
          <FilterAltOffIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        arrow
        title={
          <Stack direction={'column'}>
            <div>Hide following tags</div>

            {props.tagsToIgnore.map((tagToIgnore, index) => (
              <div key={`${tagToIgnore}-${index}`}>{tagToIgnore}</div>
            ))}
          </Stack>
        }
      >
        <Stack
          direction='row'
          spacing={0}
          data-testid='hide-specific-tags-switch'
        >
          <Switch
            checked={props.hideSpecificTags}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              props.setHideSpecificTags(checked)
            }}
          />
          <Typography style={{ marginTop: '6px' }}>
            Hide specific tags
          </Typography>
        </Stack>
      </Tooltip>
      <Tooltip arrow title='Refresh Data'>
        <IconButton
          onClick={props.refreshAction}
          data-testid='failure-table-refresh-button'
        >
          <Sync />
        </IconButton>
      </Tooltip>
      <Box sx={{ marginTop: '5px !important' }}>
        <LastSynchronizationLabel
          lastSyncAt={props.dataUpdatedAt}
          syncInProgress={props.isRefetching}
        />
      </Box>
    </Stack>
  )
}
