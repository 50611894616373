import React, { FC, useState } from 'react'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { SnackbarNotification } from '../Snackbar/SnackbarNotification'
import { MutingDialog } from './MutingDialog'
import { MutingSwitchDetailView } from './MutingSwitchDetailView'
import { MutingSwitchListView } from './MutingSwitchListView'
import Alert from '@mui/material/Alert'
import { SwitchType } from '../utils/SwitchType'

dayjs.extend(utc)

export interface MutingSwitchProps {
  caseId: string
  failureType: string
  mutedUntil?: Date
  onMute: (failureType: string, mutedUntil?: Date) => Promise<void>
  onUnmute: (failureType: string) => Promise<void>
  mutingSwitchType: SwitchType
}

export const MutingSwitch: FC<MutingSwitchProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [mutedUntil, setMutedUntil] = useState<Date | undefined>(
    props.mutedUntil
  )

  const muteStatusSwitchOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): Promise<void> => {
    if (checked) {
      setIsDialogOpen(true)
      setOpenSnackbar(true)
    } else {
      try {
        setIsLoading(true)
        await props.onUnmute(props.failureType)
        setMutedUntil(undefined)
      } catch (error) {
        setErrorMessage(
          `Could not unmute failure type ${props.failureType.toUpperCase()}`
        )
      } finally {
        setIsLoading(false)
      }
    }
  }

  const muteOnConfirm = async (chosenMutedUntil?: Date): Promise<void> => {
    try {
      if (chosenMutedUntil === undefined) {
        return
      }
      setIsLoading(true)
      await props.onMute(props.failureType, chosenMutedUntil)
      setMutedUntil(chosenMutedUntil)
    } catch (error) {
      setErrorMessage(
        `Could not mute failure type ${props.failureType.toUpperCase()}`
      )
    } finally {
      setIsLoading(false)
      setIsDialogOpen(false)
    }
  }

  const snackbarOnClose = () => {
    setOpenSnackbar(false)
    setErrorMessage(undefined)
  }

  const checkPropsAreValid = () => {
    return (
      props.caseId === undefined ||
      props.caseId === '' ||
      props.failureType === '' ||
      props.onUnmute === undefined ||
      props.onMute === undefined ||
      props.mutingSwitchType === undefined
    )
  }

  if (checkPropsAreValid()) {
    return (
      <Alert severity={'error'} variant='standard'>
        Invalid parameter for muting switch component
      </Alert>
    )
  }

  return (
    <>
      <MutingDialog
        failureType={props.failureType}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        muteOnConfirm={muteOnConfirm}
      />
      <Grid
        container
        data-testid={`${props.caseId}-${props.failureType}-muting-toggle`}
      >
        {/*TODO-51 (EG): Split muting switch into separate views and remove SwitchType enum*/}
        {props.mutingSwitchType === SwitchType.listView ? (
          <>
            <SnackbarNotification
              key={'MutingSnackbar'}
              message={mutedUntil ? 'Muted successfully' : errorMessage}
              onClose={snackbarOnClose}
              trigger={openSnackbar}
            />
            <MutingSwitchListView
              isLoading={isLoading}
              mutedUntil={mutedUntil}
              onMutedChanged={muteStatusSwitchOnChange}
            />
          </>
        ) : (
          <MutingSwitchDetailView
            isLoading={isLoading}
            mutedUntil={mutedUntil}
            onMutedChanged={muteStatusSwitchOnChange}
          />
        )}
      </Grid>
    </>
  )
}
