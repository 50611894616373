import React from 'react'
import { Button } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { FieldGroup } from './useCopyPumpInfoController'

interface Props {
  setSelectedFieldGroup: (group: FieldGroup) => void
}

export const CopyPumpInfoButtons: React.FC<Props> = ({
  setSelectedFieldGroup
}) => {
  return (
    <>
      <Button
        data-testid='copyAllInfoButton'
        size='small'
        variant='text'
        startIcon={<ContentCopyIcon />}
        onClick={() => setSelectedFieldGroup('all')}
      >
        All
      </Button>
      <Button
        data-testid='copyC4cInfoButton'
        size='small'
        variant='text'
        startIcon={<ContentCopyIcon />}
        onClick={() => setSelectedFieldGroup('c4c')}
      >
        C4C
      </Button>
      <Button
        data-testid='copyCaInfoButton'
        size='small'
        variant='text'
        startIcon={<ContentCopyIcon />}
        onClick={() => setSelectedFieldGroup('ca')}
      >
        CA
      </Button>
    </>
  )
}
