import { CircularProgress } from '@mui/material'
import React from 'react'

interface ILoadingSpinnerProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit'
  key?: string
  margin?: string
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({
  color = 'primary',
  key,
  margin = '0'
}) => {
  return (
    <CircularProgress
      color={color}
      key={key}
      style={{ height: 20, width: 20, margin: margin }}
    />
  )
}
