import { FailuresListEntry } from '../models/failuresListEntry'
import { Row } from '@tanstack/table-core/src/types'

const ALARM_STATES_ASC_ORDERED = [
  'FAILURESTATE_OKAY',
  'FAILURESTATE_NORM',
  'FAILURESTATE_WARN',
  'FAILURESTATE_ALARM'
]

export const sortingFailureState = (
  rowA: Row<FailuresListEntry>,
  rowB: Row<FailuresListEntry>,
  columnId: string
): number => {
  const stateA = rowA.getValue<string>(columnId)
  const stateB = rowB.getValue<string>(columnId)

  return (
    ALARM_STATES_ASC_ORDERED.indexOf(stateA) -
    ALARM_STATES_ASC_ORDERED.indexOf(stateB)
  )
}
