import { useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../models/failuresListEntry'
import { useCountryCodeColumnHeader } from './definitions/useCountryCodeColumnHeader'
import { useOrganizationNameColumnHeader } from './definitions/useOrganizationNameColumnHeader'
import { usePumpColumnHeader } from './definitions/usePumpColumnHeader'
import { useLocationColumnHeader } from './definitions/useLocationColumnHeader'
import { useFailureTypeColumnHeader } from './definitions/useFailureTypeColumnHeader'
import { useCurrentStateColumnHeader } from './definitions/useCurrentStateColumnHeader'
import { useHighestStateColumnHeader } from './definitions/useHighestStateColumnHeader'
import { useAssigneeColumnHeader } from './definitions/useAssigneeColumnHeader'
import { useFirstOccurrenceColumnHeader } from './definitions/useFirstOccurrenceColumnHeader'
import { useLastOccurrenceColumnHeader } from './definitions/useLastOccurrenceColumnHeader'
import { useTagsColumnHeader } from './definitions/useTagsColumnHeader'
import { useExternalLinkColumnHeader } from './definitions/useExternalLinkColumnHeader'
import { useMutedColumnHeader } from './definitions/useMutedColumnHeader'
import { useAcknowledgedColumnHeader } from './definitions/useAcknowledgedColumnHeader'
import { useReportedByColumnHeader } from './definitions/useReportedByColumnHeader'
import { useOpenDetailsColumnHeader } from './definitions/useOpenDetailsColumnHeader'

export const useFailureListColumns = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry>[] => {
  const openDetailsColumnHeader = useOpenDetailsColumnHeader()
  const countryCodeColumHeader = useCountryCodeColumnHeader(data)
  const organizationNameColumHeader = useOrganizationNameColumnHeader()
  const pumpColumnHeader = usePumpColumnHeader()
  const locationColumnHeader = useLocationColumnHeader()
  const failureTypeColumnHeader = useFailureTypeColumnHeader(data)
  const currentStateColumHeader = useCurrentStateColumnHeader(data)
  const highestStateColumHeader = useHighestStateColumnHeader(data)
  const assigneeColumHeader = useAssigneeColumnHeader(data)
  const firstOccurrenceColumHeader = useFirstOccurrenceColumnHeader()
  const lastOccurrenceColumHeader = useLastOccurrenceColumnHeader()
  const tagsColumHeader = useTagsColumnHeader(data)
  const externalLinkColumHeader = useExternalLinkColumnHeader()
  const mutedColumHeader = useMutedColumnHeader(data)
  const acknowledgedColumHeader = useAcknowledgedColumnHeader()
  const reportedByColumnHeader = useReportedByColumnHeader()

  return useMemo<MRT_ColumnDef<FailuresListEntry>[]>(() => {
    return [
      openDetailsColumnHeader,
      countryCodeColumHeader,
      organizationNameColumHeader,
      pumpColumnHeader,
      locationColumnHeader,
      reportedByColumnHeader,
      failureTypeColumnHeader,
      currentStateColumHeader,
      highestStateColumHeader,
      assigneeColumHeader,
      firstOccurrenceColumHeader,
      lastOccurrenceColumHeader,
      tagsColumHeader,
      externalLinkColumHeader,
      mutedColumHeader,
      acknowledgedColumHeader
    ]
  }, [
    openDetailsColumnHeader,
    acknowledgedColumHeader,
    assigneeColumHeader,
    countryCodeColumHeader,
    currentStateColumHeader,
    externalLinkColumHeader,
    failureTypeColumnHeader,
    firstOccurrenceColumHeader,
    reportedByColumnHeader,
    highestStateColumHeader,
    lastOccurrenceColumHeader,
    locationColumnHeader,
    mutedColumHeader,
    organizationNameColumHeader,
    pumpColumnHeader,
    tagsColumHeader
  ])
}
