import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { FailureListTagType } from '../../models/failureListTagType'
import React, { useMemo } from 'react'
import { Box, Chip, Tooltip } from '@mui/material'
import { Row } from '@tanstack/table-core/src/types'

export const tagsColumnId = 'tags'

export const useTagsColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const selectOptions = useMemo<string[]>(() => {
    const allTags = data.map((entry) => entry.tags.map((tag) => tag.value))
    const flatted = allTags.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    )
    const distinctCurrentStates = flatted.filter(
      (item, index) => flatted.indexOf(item) === index
    )

    return distinctCurrentStates.sort((a, b) =>
      a.toUpperCase() > b.toUpperCase() ? 1 : -1
    )
  }, [data])

  return {
    id: tagsColumnId,
    header: 'Tags',
    accessorKey: 'tags',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableGlobalFilter: false,
    enableResizing: true,
    filterVariant: 'multi-select',
    filterSelectOptions: selectOptions,
    minSize: 80,
    maxSize: 160,
    filterFn: (
      row: Row<FailuresListEntry>,
      columnId: string,
      filterValue: any
    ) => {
      if (!filterValue) {
        return false
      }

      const tagsString = row.getValue<string>(columnId) || ''
      const tags = tagsString
        .split(',')
        .map((tag) => tag.trim().toUpperCase())
        .filter(Boolean)

      const matchesTags = (filter: string): boolean => {
        const filterUpper = filter.toString().toUpperCase()
        return tags.some((tag) => tag.includes(filterUpper))
      }

      if (typeof filterValue === 'string') {
        if (filterValue === '') {
          return true
        }
        return matchesTags(filterValue)
      }

      if (Array.isArray(filterValue)) {
        const filterValues = filterValue as string[]

        if (filterValues.length === 0) {
          return true
        }

        return filterValues.some((filterValue) => {
          return matchesTags(filterValue)
        })
      }

      return false
    },
    accessorFn: (row: FailuresListEntry) => {
      const tags = row.tags
      return tags.map((tag) => tag.value).join(', ')
    },
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const tags = row.original.tags || []
      let index = 0
      return (
        <Box
          className='cell-tags'
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {tags.map((tag) => {
            index++
            const variant =
              tag.type === FailureListTagType.guard ? 'outlined' : 'filled'

            const tagTypeLabel =
              tag.type !== FailureListTagType.guard
                ? `USER ${tag.type}`
                : tag.type
            return (
              <Tooltip
                arrow
                key={tag.value + index}
                title={
                  <Box>
                    <strong>{tag.value}</strong>
                    <Box style={{ fontSize: '0.8em', textAlign: 'center' }}>
                      {tagTypeLabel}
                    </Box>
                  </Box>
                }
              >
                <Chip
                  label={tag.value.replace(/['"]+/g, '')}
                  key={tag.value + index}
                  variant={variant}
                  size='small'
                  data-testid={tag.value + index}
                  style={{ margin: '2px', padding: '0px', fontSize: '0.8em' }}
                />
              </Tooltip>
            )
          })}
        </Box>
      )
    }
  }
}
