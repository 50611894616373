import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { MutingSwitch } from '../../../MutingSwitch/MutingSwitch'
import { useCasesContext } from '../../../Contexts/CasesContext'
import { isFailureMuted } from '../../../MutingSwitch/isFailureMuted'
import { formatDateTimeString } from '../../../utils/formatDateString'
import { SwitchType } from '../../../utils/SwitchType'
import { getCellKey } from './column-utils'

export const isMutedColumnId = 'isMuted'

export const useMutedColumnHeader = (
  data: FailuresListEntry[]
): MRT_ColumnDef<FailuresListEntry> => {
  const { muteFailure, unmuteFailure } = useCasesContext()
  const selectOptions = useMemo<string[]>(() => ['YES', 'NO'], [])

  return {
    id: isMutedColumnId,
    header: 'Muted',
    accessorKey: 'isMuted',
    visibleInShowHideMenu: true,
    enableColumnFilter: true,
    enableGrouping: false,
    enableSorting: false,
    enableHiding: true,
    enablePinning: false,
    enableResizing: false,
    enableMultiSort: false,
    enableColumnOrdering: true,
    enableEditing: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    enableColumnFilterModes: false,
    minSize: 74,
    maxSize: 74,
    filterVariant: 'select',
    filterSelectOptions: selectOptions,
    filterFn: (row, columnId, filterValue) => {
      const isMuted = isFailureMuted(row.original.mutedUntil)
      return (
        (filterValue === 'YES' && isMuted) || (filterValue === 'NO' && !isMuted)
      )
    },
    accessorFn: (row: FailuresListEntry) => {
      const mutedUntil =
        row.mutedUntil && formatDateTimeString(new Date(row.mutedUntil))
      return mutedUntil || ''
    },
    Cell: ({ row }: { row: MRT_Row<FailuresListEntry> }) => {
      const failuresListEntry = row.original
      const caseId = failuresListEntry.pumpId
      const failureType = failuresListEntry.failureType

      const handleMute = async (type: string, until: Date | undefined) => {
        await muteFailure(type, caseId, until)
      }
      const handleUnmute = async (type: string) => {
        await unmuteFailure(type, caseId)
      }
      const cellKey = getCellKey(caseId, failureType, isMutedColumnId)

      return (
        <MutingSwitch
          key={cellKey}
          caseId={caseId}
          mutedUntil={failuresListEntry.mutedUntil}
          failureType={failureType}
          onMute={handleMute}
          onUnmute={handleUnmute}
          mutingSwitchType={SwitchType.listView}
        />
      )
    }
  }
}
