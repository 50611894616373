import { MRT_ColumnDef, MRT_FilterFn } from 'material-react-table'
import React from 'react'
import { FailuresListEntry } from '../../models/failuresListEntry'
import { Box, Typography } from '@mui/material'
import {
  formatDateString,
  formatTimeString
} from '../../../utils/formatDateString'
import { mediumFontSize } from '../cellFontSizes'
import dayjs, { Dayjs } from 'dayjs'

export const lastOccurrenceColumnId = 'lastOccurrence'

const parseDate = (date: string | Date | Dayjs | null): Date | null => {
  if (!date) return null
  if (date instanceof Date) return isNaN(date.getTime()) ? null : date
  if (dayjs.isDayjs(date)) return date.isValid() ? date.toDate() : null

  const parsedDate = dayjs(date).toDate()
  return isNaN(parsedDate.getTime()) ? null : parsedDate
}

const filterLastOccurrence: MRT_FilterFn<FailuresListEntry> = (
  row,
  columnId,
  filterValue
) => {
  if (!filterValue || filterValue.length === 0) return true

  const rowDate = parseDate(row.getValue(columnId))
  const [startDate, endDate] = filterValue.map(parseDate)

  if (!rowDate) return true
  if (!startDate && !endDate) return true
  if (startDate && endDate) return rowDate >= startDate && rowDate <= endDate
  if (startDate) return rowDate >= startDate
  if (endDate) return rowDate <= endDate

  return true
}

export const useLastOccurrenceColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => ({
    id: lastOccurrenceColumnId,
    header: 'Last Occurrence',
    accessorKey: 'lastOccurrence',
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableGlobalFilter: false,
    enableResizing: false,
    filterVariant: 'date-range',
    enableClickToCopy: false,
    minSize: 100,
    maxSize: 100,
    filterFn: filterLastOccurrence,
    accessorFn: (row) => {
      if (!row.lastOccurrence) return null
      const parsedDate = dayjs(row.lastOccurrence)
      return parsedDate.isValid() ? parsedDate.toISOString() : null
    },
    Cell: ({ row }) => {
      const lastOccurrence = parseDate(row.original.lastOccurrence)
      if (!lastOccurrence) return <Box></Box>

      return (
        <Box className='cell-last-occurrence'>
          <Typography variant='body2' fontSize={mediumFontSize}>
            {formatDateString(lastOccurrence)}
          </Typography>
          <Typography variant='body2' fontSize={mediumFontSize}>
            {formatTimeString(lastOccurrence)}
          </Typography>
        </Box>
      )
    }
  })
