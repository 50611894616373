import { MRT_ColumnDef } from 'material-react-table'
import { FailuresListEntry } from '../../models/failuresListEntry'
import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useCasesContext } from '../../../Contexts/CasesContext'
import { getCellKeyFromRow } from './column-utils'
import { AcknowledgmentSwitchListView } from '../../../AcknowledgmentSwitch/AcknowledgmentSwitchListView'

export const acknowledgedColumnId = 'isAcknowledged'

export const useAcknowledgedColumnHeader =
  (): MRT_ColumnDef<FailuresListEntry> => {
    const { updateFailureAcknowledgement } = useCasesContext()
    const selectOptions = useMemo<string[]>(() => {
      return ['YES', 'NO']
    }, [])

    return {
      id: acknowledgedColumnId,
      header: 'Acknowledged',
      accessorKey: 'isAcknowledged',
      visibleInShowHideMenu: true,
      enableColumnFilter: true,
      enableGrouping: false,
      enableSorting: false,
      enableHiding: true,
      enablePinning: false,
      enableResizing: false,
      enableMultiSort: false,
      enableColumnOrdering: true,
      enableEditing: false,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableGlobalFilter: false,
      enableColumnFilterModes: false,
      filterVariant: 'select',
      filterSelectOptions: selectOptions,
      minSize: 88,
      maxSize: 88,
      filterFn: (row, id, filterValue) => {
        const isAcknowledged = row.original.isAcknowledged
        return (
          (filterValue === 'YES' && isAcknowledged) ||
          (filterValue === 'NO' && !isAcknowledged)
        )
      },
      Cell: (data) => {
        const entry = data.row.original
        const caseId = entry.pumpId
        const isAcknowledged = entry.isAcknowledged
        const failureType = entry.failureType

        const handleAcknowledgement = async (
          isAcknowledged: boolean
        ): Promise<boolean> => {
          return updateFailureAcknowledgement({
            acknowledged: isAcknowledged,
            caseId: caseId,
            failureType: failureType
          })
        }

        const cellKey = getCellKeyFromRow(data.row, acknowledgedColumnId)
        return (
          <Box
            key={cellKey}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <AcknowledgmentSwitchListView
              caseId={caseId}
              failureType={failureType}
              acknowledged={isAcknowledged}
              onAcknowledged={handleAcknowledgement}
            ></AcknowledgmentSwitchListView>
          </Box>
        )
      }
    }
  }
