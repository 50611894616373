import { columnFiltersKey } from './localStorageKeys'
import dayjs from 'dayjs'

interface ColumnFilter {
  id: string
  value: (string | dayjs.Dayjs | null)[]
}

const formatDateForStorage = (
  date: string | dayjs.Dayjs | null
): string | null => {
  if (!date) return null
  const parsedDate = dayjs(date)
  return parsedDate.isValid() ? parsedDate.toISOString() : null
}

const formatColumnFiltersForStorage = (
  filters: ColumnFilter[]
): ColumnFilter[] => {
  return filters.map((filter) =>
    (filter.id === 'firstOccurrence' || filter.id === 'lastOccurrence') &&
    Array.isArray(filter.value)
      ? { ...filter, value: filter.value.map(formatDateForStorage) }
      : filter
  )
}

export const saveStatesToLocalStorage = (
  key: string,
  value: any,
  isTableInitialized: boolean
): void => {
  if (!isTableInitialized) return

  const valueToStore =
    key === columnFiltersKey && Array.isArray(value)
      ? formatColumnFiltersForStorage(value)
      : value

  localStorage.setItem(key, JSON.stringify(valueToStore))
}
